/*eslint-disable no-shadow */
import React, { Fragment, useEffect } from 'react';
import get from 'lodash/get';
import ProductTile from 'components/ProductTile';
import ThemeWrapper from 'containers/ThemeWrapper';
import styled from 'styled-components';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import { useGlobalProps } from 'containers/GlobalPropsContext';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { PRODUCT_IMPRESSIONS, pushEvent } from '../../utils/tracking/gtm';
import removeCurrencySymbol from '../../lib';
import { USE_COLLECTIONS } from '../../constants';
import { ProductTileCollections } from '../ProductTileCollections';
import './ProductFeed.scss';
import ProductFeedSkeleton from './ProductFeedSkeleton';
// import Pagination from 'components/Pagination';
// import { Button, Skeleton } from '@springforcreators/propel-ui';
// import { Waypoint } from 'react-waypoint';
// import tracker from 'utils/tracking';
// import DropHeading from './DropHeading';
/* eslint-disable react/jsx-one-expression-per-line */

const Container = styled.div`
    max-width: 1600px;
    margin: 0 auto;
    padding: 0 16px;
`;

const CollectionHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    a {
      color: #9E9E9E;
      text-transform: uppercase;
      border-bottom: 1px solid #9E9E9E;
    }
`;

const CollectionTiles = styled.div`
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 16px;
    @media (max-width: 768px) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
`;

const CollectionImg = styled.img`
    width: 100%;
    height: auto;
    border-radius: 8px;
    transition: all 0.8s ease;
    &:hover {
      opacity: 0.5;
      transform: scale(1.02);
    }
`;

const ProductFeed = (props) => {
  const {
    storeProducts,
    storeCollections,
    isFetching,
    storeData
    //fetchProducts,
    //themeData
    // getStyles
  } = useGlobalProps();

  const {
    // title,
    localizationData,
    storeName
    // previewMode,
    // storeListings
  } = props;

  // const { layout, content } = themeData;

  const {
    products
    //page,
    //totalPages,
    //next
  } = storeProducts;
  const perPage = storeProducts.per_page;
  // const showMoreProducts = USE_COLLECTIONS ? page !== totalPages : !!next;
  // const { productCacheEnabled } = storeListings;
  // const currentPage = page;

  // let tiles = [];

  /*  if (products && products.length) {
    tiles = products.map((product, position) => {
      if (USE_COLLECTIONS) {
        return <ProductTileCollections product={ product } key={ product.id } list="Featured Products" position={ position } />;
      } else {
        return <ProductTile product={ product } key={ product.id } list="Featured Products" position={ position } />;
      }
    });
  } */

  // collections
  const cheapCollectionId = 'cheap';
  const storeCollection = storeCollections[cheapCollectionId];
  const cheapProducts = get(storeCollection, 'products', []);
  const cheapremeCollectionId = 'cheapreme';
  const storeCheapremeCollection = storeCollections[cheapremeCollectionId];
  const cheapremeProducts = get(storeCheapremeCollection, 'products', []);

  let cheapProductsTiles = [];

  if (products && products.length) {
    cheapProductsTiles = cheapProducts.map((product, position) => {
      if (USE_COLLECTIONS) {
        return <ProductTileCollections product={ product } key={ product.id } list="Featured Products" position={ position } />;
      } else {
        return <ProductTile product={ product } key={ product.id } list="Featured Products" position={ position } />;
      }
    });
  }

  let cheapremeProductsTiles = [];

  if (products && products.length) {
    cheapremeProductsTiles = cheapremeProducts.map((product, position) => {
      if (USE_COLLECTIONS) {
        return <ProductTileCollections product={ product } key={ product.id } list="Featured Products" position={ position } />;
      } else {
        return <ProductTile product={ product } key={ product.id } list="Featured Products" position={ position } />;
      }
    });
  }

  /*  const handleLoadMore = () => {
    tracker.track(`homepage.load_more.clicked`, { productCount: products.length });
    fetchProducts(currentPage + 1);
  }; */

  useEffect(() => {
    if (products.length) {
      const latestFetchedProducts = products.slice(products.length - perPage || 0, products.length);
      const productImpressions = latestFetchedProducts.map((product, position) => (
        {
          name: get(product, 'name'),
          id: get(product, 'listingId'),
          price: removeCurrencySymbol(get(product, 'price')),
          brand: storeName,
          category: get(product, 'productName'),
          list: 'Featured Products',
          position: position + 1,
          dimension8: get(storeData, 'sellerId'),
          dimension9: get(product, 'listingId')
        }
      ));
      const eventBody = {
        currencyCode: get(localizationData, 'buyer_currency'),
        impressions: productImpressions
      };
      pushEvent(PRODUCT_IMPRESSIONS, eventBody);
    }
  }, [products]);

  return (
    <Fragment>
      <div className="anchor" id="featured-products" />
      <div className="product-feed" style={ { border: 'none' } }>
        <Container>
          <CollectionTiles>
            <Link to="/cheapreme">
              <CollectionImg src="assets/cheapreme_card.png" alt="Cheapreme collection" />
            </Link>
            <Link to="/cheap">
              <CollectionImg src="assets/cheap_card.png" alt="Cheap collection" />
            </Link>
          </CollectionTiles>
        </Container>
      </div>
      <div className="product-feed">
        <Container>
          <CollectionHeader>
            <div className="tile-heading">
              <h2>Cheapreme collection</h2>
            </div>
            <Link to="/cheapreme">View collection</Link>
          </CollectionHeader>
          { isFetching && !products?.length && (
            <ProductFeedSkeleton />
          ) }
          { cheapProductsTiles }
        </Container>
      </div>
      <div className="product-feed">
        <Container>
          <CollectionHeader>
            <div className="tile-heading">
              <h2>Cheap collection</h2>
            </div>
            <Link to="/cheapreme">View collection</Link>
          </CollectionHeader>
          { cheapremeProductsTiles }
        </Container>
      </div>

      {/* <div className="product-feed">
        <div className="tile-heading" style={ { display: 'none' } }>
          {isFetching && !products?.length ? (
            <Skeleton styles={ { height: 30, width: '45%' } } />
          ) : (
            <DropHeading title="Combat Club Collection" body={ get(content, 'footer.about.textContent') } />
          )}
        </div>
        { isFetching && !products?.length && (
          <ProductFeedSkeleton />
        ) }

        { (previewMode || get(layout, 'productList.pagination'))
          ? <Pagination items={ tiles } />
          : <div className="product-tile-grid" data-cy="product-tile-grid" style={ { display: 'none' } }>{ tiles }</div>
        }

        { showMoreProducts && !productCacheEnabled && (
          <>
            { currentPage < 2 ? (
              <Waypoint
                onEnter={
                  (wp) => {
                    if (!isFetching && wp.previousPosition === 'below' && !get(layout, 'productList.pagination')) {
                      const { page } = storeProducts;
                      fetchProducts(page + 1);
                    }
                  }
                }
              />
            ) : (
              <div className="center-content mb4">
                { !isFetching && (
                  <Button
                    onClick={ handleLoadMore }
                  >
                    Load More
                  </Button>
                )}
              </div>
            )}
          </>
        ) }
      </div> */}

    </Fragment>
  );
};

const { string, shape, bool } = propTypes;

ProductFeed.propTypes = {
  title: string,
  localizationData: shape({}).isRequired,
  currency: string.isRequired,
  storeName: string,
  previewMode: bool.isRequired,
  storeListings: shape({}).isRequired
};

ProductFeed.defaultProps = {
  title: null,
  storeName: ''
};

const mapStateToProps = state => ({
  localizationData: state.localizationData,
  storeName: state.stores.name,
  previewMode: get(state, 'themeData.previewMode'),
  storeListings: get(state, 'storeListings'),
  currency: state.localizationData.buyer_currency
});

export default connect(mapStateToProps)(ThemeWrapper(ProductFeed, ['content.productList', 'styles.productList', 'layout.productList']));
